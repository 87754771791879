import { createSlice } from '@reduxjs/toolkit';
import { mergeObjects, isObject } from '../../utils/Helpers';

const initialState = {
    isOpen: false,
    selectedRole: '',
    activeComponent: 'initial',
    quitConfirm: false,
    initial: {
        selectedRole: '',
        activeComponent: 'form',
        mobile: '',
        email: '',
        token: '',
        cognitoUser: '',
    },
};

const quizContents = createSlice({
    name: 'quizContents',
    initialState,
    reducers: {
        updateListQuiz(state, action) {
            const { payload } = action;
            if (payload) {
                Object.keys(payload).forEach((key) => {
                    const value = payload[key];
                    if (isObject(value)) state[key] = mergeObjects(state[key], value);
                    else state[key] = value;
                });
            }
        },
        resetStateQuiz(state) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export const quizContentActions = quizContents.actions;
export default quizContents.reducer;
