import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoaded: false
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    updateIsLoaded(state, action) {
      state.isLoaded = action.payload
    },
  },
});

export const getIsLoadedStatus = (state) => state.system;
export const { updateIsLoaded } = systemSlice.actions;
export default systemSlice.reducer;