import { configureStore } from '@reduxjs/toolkit';

//slice
import homeContentReducers from './reducers/home.reducers';
import quizContentReducers from './reducers/quiz.reducers';
import builderContentReducers from './reducers/builder.reducers';
import systemReducer from './reducers/system.reducers';

const rootReducer = {
    homeContents: homeContentReducers,
    quizContents: quizContentReducers,
    builderContents: builderContentReducers,
    system: systemReducer 
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
