import { createSlice } from '@reduxjs/toolkit';
import { mergeObjects, isObject } from '../../utils/Helpers';

//SVGs
import icon_mic from '../../../static/svg/microphone.svg'; 
import icon_heart from '../../../static/svg/heart-tick.svg';
import icon_gps from '../../../static/svg/location.svg';
import icon_btoot from '../../../static/svg/bluetooth.svg';
import icon_wifi from '../../../static/svg/wifi.svg';
import icon_cloud from '../../../static/svg/cloud-connection.svg';
import icon_mobile from '../../../static/svg/mobile.svg';
import icon_watch from '../../../static/svg/watch-status.svg';
import icon_home from '../../../static/svg/house.svg';

const initialState = {
  builderMode: false,
  activePage: 'login',
  pageLogin: {
    form: {
      business: '',
      email: '',
      mobile: '',
      industry: '',
      isLoading: false,
    },
    solutions: [],
    activeComponent: 'form',
    industries: [
      {
        name: 'Agriculture',
        value: 'agriculture',
      },
      {
        name: 'Chemical Industries',
        value: 'chemical',
      },
      {
        name: 'Commerce',
        value: 'commerce',
      },
      {
        name: 'Construction',
        value: 'construction',
      },
      {
        name: 'Education',
        value: 'education',
      },
      {
        name: 'Financial Service',
        value: 'finance',
      },
    ],
  },
  pageBuilder: {
    modal: {
      isShow: false,
    },
    activeSolutions: [],
    menuIOT: [
      {
        category: 'sensor',
        header: 'Sensing Layer',
        components: [
          {
            name: 'Microphone',
            icon: icon_mic,
            categ: 'Sensing Layer',
          },
          {
            name: 'Heart Rate Sensor',
            icon: icon_heart,
            categ: 'Sensing Layer',
          },
          {
            name: 'GPS',
            icon: icon_gps,
            categ: 'Sensing Layer',
          },
        ],
      },
      {
        category: 'network',
        header: 'Network Layer',
        components: [
          {
            name: 'Bluetooth',
            icon: icon_btoot,
            categ: 'Network Layer',
          },
          {
            name: 'WiFi Network',
            icon: icon_wifi,
            categ: 'Network Layer',
          },
          {
            name: 'Cloud Network',
            icon: icon_cloud,
            categ: 'Network Layer',
          },
        ],
      },
      {
        category: 'app',
        header: 'Application Layer',
        components: [
          {
            name: 'Mobile App',
            icon: icon_mobile,
            categ: 'Application Layer',
          },
          {
            name: 'Smart Watch',
            icon: icon_watch,
            categ: 'Application Layer',
          },
          {
            name: 'Smart Home',
            icon: icon_home,
            categ: 'Application Layer',
          },
        ],
      },
    ],
    submitDisabled: false,
  },
  pageWorkspace: {
    mainMenu: [
      {
        category: 'sensor',
        header: 'Sensing Layer',
        components: [],
      },
      {
        category: 'network',
        header: 'Network Layer',
        components: [],
      },
      {
        category: 'app',
        header: 'Application Layer',
        components: [],
      },
    ],
  },
  pdfData: {
    triggerRun: false,
  },
  tutsMode: {
    isActivated: true,
    activeStep: 'sider',
    //sider, sidertitle, sidermenu, content
  },
  user: {
    isLogged: false,
    token: '',
  },
};

const builderContents = createSlice({
  name: 'builderContents',
  initialState,
  reducers: {
    updateListBuilder(state, action) {
      const { payload } = action;
      if (payload) {
        Object.keys(payload).forEach((key) => {
          const value = payload[key];
          if (isObject(value)) state[key] = mergeObjects(state[key], value);
          else state[key] = value;
        });
      }
    },
    resetStateBuilder(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    updateIOTMenu(state, action) {
      const currIOTMenu = state.pageBuilder.menuIOT;
      currIOTMenu.push(action.payload);
    },
    updateIOTComponent(state, action) {
      const currIOTMenu = state.pageBuilder.menuIOT;
      currIOTMenu.reduce((acc, menu) => {
        if (menu.header === action.payload.categ) {
          menu.components.push(action.payload);
        }
        acc.push(menu);
        return acc;
      }, []);
    },
    updateWSComponents(state, action) {
      const currMenu = state.pageWorkspace.mainMenu;
      currMenu.reduce((acc, menu) => {
        if (menu.header === action.payload.categ) {
          menu.components.push(action.payload);
        }
        acc.push(menu);
        return acc;
      }, []);
    },
    updateWSMenu(state, action) {
      const currMenu = state.pageWorkspace.mainMenu;
      currMenu.push(action.payload);
    },
    updateWSSortedComponent(state, action) {
      const currMenu = state.pageWorkspace.mainMenu;
      const myCateg = action.payload[0].categ;
      currMenu.reduce((acc, menu) => {
        if (menu.header === myCateg) {
          menu.components = action.payload;
        }
        acc.push(menu);
        return acc;
      }, []);
    },
    deleteComponentsItem(state, action) {
      const currMenu = state.pageWorkspace.mainMenu;
      currMenu.reduce((acc, menu) => {
        if (menu.header === action.payload.categ) {
          const mustRemain = menu.components.filter((data) => {
            return data.name !== action.payload.name;
          });

          menu.components = mustRemain;
        }
        acc.push(menu);
        return acc;
      }, []);
    },
  },
});

export const builderContentActions = builderContents.actions;
export default builderContents.reducer;